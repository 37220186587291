import { useEffect, useState } from 'react';
import './front.scss';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { makeClassNameList } from '../../utils';


interface NavItem {
    name: string
    path: string
}

const FrontMainLayout = () => {

    const navigate = useNavigate();
    const routerLocation = useLocation();

    const [curentRoutePath, setCurrentRoutePath] = useState(routerLocation.pathname);
    const [navList] = useState<NavItem[]>([
        {
            name: "首页",
            path: "/home",
        },
        {
            name: "学习动态",
            path: "/learning-dynamics",
        },
        {
            name: "党组织架构",
            path: "/party-organizational",
        },
        {
            name: "党员风采",
            path: "/party-members-show",
        },
    ])

    useEffect(() => {
        console.log("routerLocation--->", routerLocation)
        setCurrentRoutePath(routerLocation.pathname);
    }, [routerLocation])


    const jupmToPage =(nav: NavItem) => {
        navigate(nav.path)
        // setCurrentRoutePath(nav.path)
    }
    return (
        <div className="front-layout-page">
            <div className="flex-row front-layout-page-nav">
                <div className='front-layout-page-nav-title'>智慧党建</div>
                <div className='flex-row front-layout-page-nav-menu'>
                    {
                        navList.map((navItem, index) => {
                            return (
                                <div
                                    onClick={() => jupmToPage(navItem)}
                                    className={makeClassNameList([
                                        'front-layout-page-nav-menu-item',
                                        curentRoutePath == navItem.path ? 'active' : null
                                    ])}
                                    key={index + ''}
                                >
                                    {navItem.name}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className='flex-col main-layout-content'>
                <Outlet />
            </div>
        </div>
    )
}

export default FrontMainLayout;