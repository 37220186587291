

export const makeClassNameList = (classNameList: Array<string | any>) => {
    try {
        let classNameStr = '';
        classNameList.forEach(className => {
            if (className) {
                classNameStr = classNameStr + ' ' + className;
            }
        })
        return classNameStr.trim();
    } catch (e) {
        return '';
    }
}