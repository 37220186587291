
interface Props {
    icon?: string
    desc?: string
    title?: string
    date?: string

}

const MemberArticleItem = (props: Props) => {

    const {
        icon,
        date,
        desc,
        title
    } = props;

    return (
        <div className="flex-row member-article-item">
            <div className="member-article-item-img">
                <img src={icon} />
            </div>
            <div className="flex-col member-article-item-right">
                <div className="flex-col">
                    <div className="member-article-item-title">{title}</div>
                    <div className="member-article-item-desc">{desc}</div>
                </div>
                <div className="member-article-item-date">{date}</div>
            </div>
        </div>
    )
}


export default MemberArticleItem;