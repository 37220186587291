import { Image } from "antd"
import { CSSProperties } from "react"

interface Props {
    cover: string
    title: string
    date: string
    style?: CSSProperties
}

const VideoItem = (props: Props) => {
    const {
        cover,
        title,
        date,
        style = {}
    } = props;

    return (
        <div className="flex-col video-item" style={style}>
            <div className="video-item-img-container">
                <img src={cover} />
            </div>
            <div className="video-item-title">{title}</div>
            <div className="video-item-date">{date}</div>
        </div>
    )
}

export default VideoItem;