//@ts-ignore
import OrgTree from 'react-org-tree';


const PartyOrganizational = () => {

    const horizontal = false; // true：横向  false：纵向
    const collapsable = false; // true：可折叠 false：不可折叠 
    const expandAll = true; // true: 全部展开 false：全部折叠 

    const data = {
        id: 0,
        label: 'xxxxxxxxxxxxxxxx有限公司总支部委员会',
        children: [
            {
                id: 4,
                label: 'xxxxxxxx有限公司第一支部委员会',
                children: [
                    {
                        id: 7,
                        label: '第一小组'
                    }, {
                        id: 8,
                        label: '第一小组'
                    }, {
                        id: 9,
                        label: '第一小组'
                    }
                ]
            }, {
                id: 5,
                label: 'xxxx有限公司第一支部委员会',
                children: [
                    {
                        id: 7,
                        label: '第一小组'
                    }, {
                        id: 8,
                        label: '第一小组'
                    }, {
                        id: 9,
                        label: '第一小组'
                    }
                ]
            }, {
                id: 6,
                label: 'xxxxxxxxxxxxxxxx有限公司第一支部委员会',
                children: [
                    {
                        id: 7,
                        label: '第一小组'
                    }, {
                        id: 8,
                        label: '第一小组'
                    }, {
                        id: 9,
                        label: '第一小组'
                    },
                    {
                        id: 9,
                        label: '第一小组第一小组第一小组'
                    }
                ]
            }
        ]
    }

    return (
        <div className="front-page member-show-bg">
            {/* <div className='title-row-2'>
                xxxxxxxxxxxxxxxx有限公司总支部委员会
            </div> */}
            <div className="flex-row title-row-1">
                <div className="title-row-1-text">xxx单位党组织架构</div>
            </div>
            <OrgTree
                data={data}
                horizontal={horizontal}
                collapsable={collapsable}
                expandAll={expandAll}
                labelClassName={'test'}
            />

        </div>
    )
}

export default PartyOrganizational;