import { makeClassNameList } from "../../../utils"


interface Props {
    icon: any
    name: string
    desc: string
    active?: boolean
}

const MemberCard = (props: Props) => {

    const {
        icon,
        name,
        desc,
        active = false,
    } = props;

    return (
        <div className={
            makeClassNameList([
                "member-card",
                active ? 'active' : null
            ])
        }>
            <img
                src={icon}
                className="member-card-img"
                
            />
            <div className="flex-row member-card-bottom">
                <div className="member-card-name">{name}</div>
            </div>
        </div>
    )
}

export default MemberCard;