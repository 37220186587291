import React, { ReactNode, Suspense, lazy, useEffect } from 'react';
import './App.css';
import type { FC } from 'react';
import locale from 'antd/locale/zh_CN';
import 'nprogress/nprogress.css';
//@ts-ignore
import NProgress from 'nprogress';
import { BrowserRouter, Routes, Route, Link, HashRouter } from 'react-router-dom';
import KeepAlive, { AliveScope, useAliveController } from 'react-activation';
import { message } from 'antd';
import 'antd/dist/reset.css';
import './assets/styles/com.scss';
import { Provider } from "react-redux";
import { ConfigProvider } from 'antd';
import FrontMainLayout from './views/front-main-layout/FrontMainLayout';
import BackMainLayout from './views/back-main-layout/BackMainLayout';
import FrontHomePage from './views/part-building-front/FrontHomePage';
import FrontLearningDynamics from './views/part-building-front/FrontLearningDynamics';
import PartyOrganizational from './views/part-building-front/PartyOrganizational';
import PartyMembersShow from './views/part-building-front/PartyMembersShow';
import UserManage from './views/part-building-back/UserManage';
import VideoManage from './views/part-building-back/VideoManage';
import ArticleManage from './views/part-building-back/ArticleManage';
import PartyMembersManage from './views/part-building-back/PartyMembersManage';
import PartyOrganizationalManage from './views/part-building-back/PartyOrganizationalManage';
const MainPage = lazy(() => import('./views/part-building-front/FrontHomePage'));



const MyBrowserRouter = BrowserRouter;

const App: FC = () => {

  useEffect(() => {
    message.config({
      top: 27,
    });
    localStorage.removeItem('confirmedToChangeSystemParagraph');
    return () => {
    }
  }, [])

  const LoadingProgress: FC = () => {
    useEffect(() => {
      NProgress.start();
      return () => {
        NProgress.done();
      }
    })
    return (
      <div></div>
    )
  };

  const lazyLoadRouteEl = (chilren: ReactNode) => {
    return (<Suspense
      fallback={
        <LoadingProgress />
      }>
      {chilren}
    </Suspense>)
  }

  return (
    <div className="App app-outer-container">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '',
          },
          components: {
            Button: {
              colorPrimary: '#ae3936',
              // colorPrimaryBg: saasConfig.colorConfig.colorPrimary,
            }
          }
        }}
        locale={locale}
      >
        <AliveScope>
          <MyBrowserRouter>
            <Routes>
              {/* 
                //首页
                //学习动态
                //党组织架构
                //党员风采 
              */}
              <Route path='/' element={lazyLoadRouteEl(<FrontMainLayout />)}>
                <Route path="home" element={lazyLoadRouteEl(<FrontHomePage />)}></Route>
                <Route path="learning-dynamics" element={lazyLoadRouteEl(<FrontLearningDynamics />)}></Route>
                <Route path="party-organizational" element={lazyLoadRouteEl(<PartyOrganizational />)}></Route>
                <Route path="party-members-show" element={lazyLoadRouteEl(<PartyMembersShow />)}></Route>
              </Route>
              {/* 
                //用户管理
                //学习动态 视频宣讲、理论武装
                //党员风采
                //党组织架构 
              */}
              <Route path='/back' element={lazyLoadRouteEl(<BackMainLayout />)}>
                <Route path="user-manage" element={lazyLoadRouteEl(<UserManage />)}></Route>
                <Route path="video-manage" element={lazyLoadRouteEl(<VideoManage />)}></Route>
                <Route path="article-manage" element={lazyLoadRouteEl(<ArticleManage />)}></Route>
                <Route path="party-members-manage" element={lazyLoadRouteEl(<PartyMembersManage />)}></Route>
                <Route path="party-organizational-manage" element={lazyLoadRouteEl(<PartyOrganizationalManage />)}></Route>
              </Route>
            </Routes>
          </MyBrowserRouter>
        </AliveScope>
      </ConfigProvider>
    </div>
  );
}

export default App;
