import { Right } from "@icon-park/react";
import { useState } from "react";
import VideoItem from "./components/VideoItem";
import ArticleItem from "./components/ArticleItem";


const FrontLearningDynamics = () => {

    const [videoList] = useState([
        {
            cover: require('./../../assets/images/2024052411575178681.jpg'),
            title: "节前教育｜坚决纠治“四风”问题",
            date: "2024-03-15",
        },
        {
            cover: require('./../../assets/images/2024052411575178681.jpg'),
            title: "节前教育｜坚决纠治“四风”问题",
            date: "2024-03-15",
        },
        {
            cover: require('./../../assets/images/2024052411575178681.jpg'),
            title: "节前教育｜坚决纠治“四风”问题",
            date: "2024-03-15",
        },
        {
            cover: require('./../../assets/images/2024052411575178681.jpg'),
            title: "节前教育｜坚决纠治“四风”问题",
            date: "2024-03-15",
        },
        {
            cover: require('./../../assets/images/2024052411575178681.jpg'),
            title: "节前教育｜坚决纠治“四风”问题",
            date: "2024-03-15",
        },
        {
            cover: require('./../../assets/images/2024052411575178681.jpg'),
            title: "节前教育｜坚决纠治“四风”问题",
            date: "2024-03-15",
        },
        {
            cover: require('./../../assets/images/2024052411575178681.jpg'),
            title: "节前教育｜坚决纠治“四风”问题",
            date: "2024-03-15",
        },
        {
            cover: require('./../../assets/images/2024052411575178681.jpg'),
            title: "节前教育｜坚决纠治“四风”问题",
            date: "2024-03-15",
        },
    ])

    const [articleList] = useState([
        {
            title: "节前教育｜坚决纠治“四风”问题",
            date: "2024-03-15",
        },
        {
            title: "节前教育｜坚决纠治“四风”问题",
            date: "2024-03-15",
        },
        {
            title: "节前教育｜坚决纠治“四风”问题",
            date: "2024-03-15",
        },
        {
            title: "节前教育｜坚决纠治“四风”问题",
            date: "2024-03-15",
        },
        {
            title: "节前教育｜坚决纠治“四风”问题",
            date: "2024-03-15",
        },
        {
            title: "节前教育｜坚决纠治“四风”问题",
            date: "2024-03-15",
        },
        {
            title: "节前教育｜坚决纠治“四风”问题",
            date: "2024-03-15",
        },
        {
            title: "节前教育｜坚决纠治“四风”问题",
            date: "2024-03-15",
        },
        {
            title: "节前教育｜坚决纠治“四风”问题",
            date: "2024-03-15",
        },
        {
            title: "节前教育｜坚决纠治“四风”问题",
            date: "2024-03-15",
        },
    ])

    return (
        <div className="flex-col front-page">
            <div className="flex-row title-row">
                <div className="title-row-text">
                    <span className="title-row-main-text">视频</span>
                    宣讲
                </div>
                <div className="flex-row title-row-more-btn">
                    更多
                    <Right theme="outline" size="15" fill="#fff" />
                </div>
            </div>
            <div className="flex-row video-list">
                {
                    videoList.map((video, index) => {
                        return (
                            <VideoItem
                                key={index + ''}
                                date={video.date}
                                title={video.title}
                                cover={video.cover}
                                style={{ marginRight: (index + 1) % 3 == 0 ? 0 : 24 }}
                            />
                        )
                    })
                }
            </div>
            <div className="flex-row title-row">
                <div className="title-row-text">
                    <span className="title-row-main-text">理论</span>
                    武装
                </div>
                <div className="flex-row title-row-more-btn">
                    更多
                    <Right theme="outline" size="15" fill="#fff" />
                </div>
            </div>
            <div className="flex-row article-list">
                {
                    articleList.map((article, index) => {
                        return (
                            <ArticleItem
                                key={index + ''}
                                date={article.date}
                                title={article.title}
                                style={{ marginRight: (index + 1) % 3 == 0 ? 0 : 24 }}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default FrontLearningDynamics;