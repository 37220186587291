import { useState } from "react";
import MemberCard from "./components/MemberCard";
import { Left, Right } from "@icon-park/react";
import MemberArticleItem from "./components/MemberArticleItem";


const PartyMembersShow = () => {

    const [memberList] = useState([
        {
            icon: require('./../../assets/images/OIP.jpeg'),
            name: '任佳佳',
            desc: "诚实正直、稳重乐观，对待工作严谨认真，刻苦耐劳、注重工作效率和团队合作，诚实正直、稳重乐观，对待工作严谨认真，刻苦耐劳、注重工作效率和团队合作，善于沟通协调，注重学习，乐于接受新事物，有较强的科研创新能力。多年的学校和研究所学习及工作经历，使我掌握了深厚的材料设计、冶炼与成形（铸造、锻造、挤压、轧制、热处理等）、材料组织与性能表征及其基础关系的专业知识，具有极大的热忱投身于高等教育事业中。",
        },
        {
            icon: require('./../../assets/images/OIP.jpeg'),
            name: '任佳佳',
            desc: "履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历"
        },
        {
            icon: require('./../../assets/images/OIP.jpeg'),
            name: '任佳佳',
            desc: "履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历"
        },
        {
            icon: require('./../../assets/images/OIP.jpeg'),
            name: '任佳佳',
            desc: "履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历"
        },
        {
            icon: require('./../../assets/images/OIP.jpeg'),
            name: '任佳佳',
            desc: "履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历"
        },
        {
            icon: require('./../../assets/images/OIP.jpeg'),
            name: '任佳佳',
            desc: "履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历"
        },
        {
            icon: require('./../../assets/images/OIP.jpeg'),
            name: '任佳佳',
            desc: "履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历"
        },
        {
            icon: require('./../../assets/images/OIP.jpeg'),
            name: '任佳佳',
            desc: "履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历"
        },
        {
            icon: require('./../../assets/images/OIP.jpeg'),
            name: '任佳佳',
            desc: "履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历"
        },
        {
            icon: require('./../../assets/images/OIP.jpeg'),
            name: '任佳佳',
            desc: "履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历"
        },
        {
            icon: require('./../../assets/images/OIP.jpeg'),
            name: '任佳佳',
            desc: "履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历履历"
        },
    ]);

    const [articleList] = useState([
        {
            icon: require('./../../assets/images/2024032610503849126.jpg'),
            title: "系列微纪录片《加快形成新质生产力》",
            desc: "国家相关部委主要负责人、中国工程院院士及前沿行业顶尖专家权威解读新能源、新材料、海洋装备、高端装备、深海空天开发以及航空航天等战略性新兴产业及未来产业的发展轨迹，见证中国经济加快形成新质生产力的蓬勃进程。",
            date: "2024-06-03",
        },
        {
            icon: require('./../../assets/images/2024032610503849126.jpg'),
            title: "系列微纪录片《加快形成新质生产力》",
            desc: "国家相关部委主要负责人、中国工程院院士及前沿行业顶尖专家权威解读新能源、新材料、海洋装备、高端装备、深海空天开发以及航空航天等战略性新兴产业及未来产业的发展轨迹，见证中国经济加快形成新质生产力的蓬勃进程。",
            date: "2024-06-03",
        },
        {
            icon: require('./../../assets/images/2024032610503849126.jpg'),
            title: "系列微纪录片《加快形成新质生产力》",
            desc: "国家相关部委主要负责人、中国工程院院士及前沿行业顶尖专家权威解读新能源、新材料、海洋装备、高端装备、深海空天开发以及航空航天等战略性新兴产业及未来产业的发展轨迹，见证中国经济加快形成新质生产力的蓬勃进程。",
            date: "2024-06-03",
        },
        {
            icon: require('./../../assets/images/2024032610503849126.jpg'),
            title: "系列微纪录片《加快形成新质生产力》",
            desc: "国家相关部委主要负责人、中国工程院院士及前沿行业顶尖专家权威解读新能源、新材料、海洋装备、高端装备、深海空天开发以及航空航天等战略性新兴产业及未来产业的发展轨迹，见证中国经济加快形成新质生产力的蓬勃进程。",
            date: "2024-06-03",
        },
        {
            icon: require('./../../assets/images/2024032610503849126.jpg'),
            title: "系列微纪录片《加快形成新质生产力》",
            desc: "国家相关部委主要负责人、中国工程院院士及前沿行业顶尖专家权威解读新能源、新材料、海洋装备、高端装备、深海空天开发以及航空航天等战略性新兴产业及未来产业的发展轨迹，见证中国经济加快形成新质生产力的蓬勃进程。",
            date: "2024-06-03",
        },
        {
            icon: require('./../../assets/images/2024032610503849126.jpg'),
            title: "系列微纪录片《加快形成新质生产力》",
            desc: "国家相关部委主要负责人、中国工程院院士及前沿行业顶尖专家权威解读新能源、新材料、海洋装备、高端装备、深海空天开发以及航空航天等战略性新兴产业及未来产业的发展轨迹，见证中国经济加快形成新质生产力的蓬勃进程。",
            date: "2024-06-03",
        },
        {
            icon: require('./../../assets/images/2024032610503849126.jpg'),
            title: "系列微纪录片《加快形成新质生产力》",
            desc: "国家相关部委主要负责人、中国工程院院士及前沿行业顶尖专家权威解读新能源、新材料、海洋装备、高端装备、深海空天开发以及航空航天等战略性新兴产业及未来产业的发展轨迹，见证中国经济加快形成新质生产力的蓬勃进程。",
            date: "2024-06-03",
        },
        {
            icon: require('./../../assets/images/2024032610503849126.jpg'),
            title: "系列微纪录片《加快形成新质生产力》",
            desc: "国家相关部委主要负责人、中国工程院院士及前沿行业顶尖专家权威解读新能源、新材料、海洋装备、高端装备、深海空天开发以及航空航天等战略性新兴产业及未来产业的发展轨迹，见证中国经济加快形成新质生产力的蓬勃进程。",
            date: "2024-06-03",
        },
        {
            icon: require('./../../assets/images/2024032610503849126.jpg'),
            title: "系列微纪录片《加快形成新质生产力》",
            desc: "国家相关部委主要负责人、中国工程院院士及前沿行业顶尖专家权威解读新能源、新材料、海洋装备、高端装备、深海空天开发以及航空航天等战略性新兴产业及未来产业的发展轨迹，见证中国经济加快形成新质生产力的蓬勃进程。",
            date: "2024-06-03",
        },
        {
            icon: require('./../../assets/images/2024032610503849126.jpg'),
            title: "系列微纪录片《加快形成新质生产力》",
            desc: "国家相关部委主要负责人、中国工程院院士及前沿行业顶尖专家权威解读新能源、新材料、海洋装备、高端装备、深海空天开发以及航空航天等战略性新兴产业及未来产业的发展轨迹，见证中国经济加快形成新质生产力的蓬勃进程。",
            date: "2024-06-03",
        },
        {
            icon: require('./../../assets/images/2024032610503849126.jpg'),
            title: "系列微纪录片《加快形成新质生产力》",
            desc: "国家相关部委主要负责人、中国工程院院士及前沿行业顶尖专家权威解读新能源、新材料、海洋装备、高端装备、深海空天开发以及航空航天等战略性新兴产业及未来产业的发展轨迹，见证中国经济加快形成新质生产力的蓬勃进程。",
            date: "2024-06-03",
        },
    ])

    return (
        <div className="front-page">
            {/* 党员先锋 */}
            <div className="flex-row title-row-1">
                <div className="title-row-1-text">党员先锋</div>
            </div>
            <div className="flex-col party-member-group">
                <div className="flex-row party-member-group-list">
                    {
                        memberList.map((member, index) => {
                            return (
                                <MemberCard
                                    icon={member.icon}
                                    name={member.name}
                                    desc={member.desc}
                                    active={index == 0}
                                />
                            )
                        })
                    }

                </div>
                <div className="flex-row left-btn">
                    <Left theme="outline" size="24" fill="#fff" />
                </div>
                <div className="flex-row right-btn">
                    <Right theme="outline" size="24" fill="#fff" />
                </div>
                {/* <div className="track-line">

                </div> */}
                <div className="desc-content">
                    {memberList[0].desc}
                </div>
            </div>
            <div className="flex-row title-row-1">
                <div className="title-row-1-text">党员风采</div>
            </div>
            {
                articleList.map((article,index) => {
                    return (
                        <MemberArticleItem
                            title={article.title}
                            desc={article.desc}
                            icon={article.icon}
                            date={article.date}
                        />
                    )
                })
            }
        </div>
    )
}

export default PartyMembersShow;